function loading(action, div)
{
	if ('start' == action) {
		$(div).addClass('position-relative');
		$(div).append('<div id="loading_div" style="position: absolute;width: 100%;height: 100%;z-index: 999;background-color: rgba(255,255,255,0.8);color: #000;font-weight: bold;left: 0;top: 0;" class="align-items-center d-flex justify-content-center">Loading...</div>');
	}
	if ('stop' == action) {
		$(div).removeClass('position-relative');
		$('#loading_div').remove();
	}
}